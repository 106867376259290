@import url('https://fonts.googleapis.com/css?family=Indie+Flower#standard-styles');
.App {
  text-align: center;
  font-family: 'Indie Flower', sans-serif;
  font-weight: 800;
  font-size: 2rem;
}

wired-card {
  max-width: 42rem;
  margin: 0 auto;
  padding: 1rem;
  display: block;
}

wired-card > div {
  padding: 0.2rem;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 10rem;
  /* border: #282c34 1px solid; */
  /* padding: 0.2rem; */
  /* margin: 0.2rem; */
}

.game{
  min-height: 60vw;
}

img {
  max-width: 12rem;
  max-height: 12rem;
}

.your-play-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
